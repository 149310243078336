
import { Component, Vue } from "vue-property-decorator";
import { OrderService } from "src/services";
import { Toast } from "vant";
import { Payment_Type } from "src/enums";
import { tools } from "src/utils";
import globalConfig from "src/config/index";
declare let WeixinJSBridge: any;

@Component
export default class Login extends Vue {
  private payType: string = "1";

  /**
   * 判断是否数微信环境
   * @private
   * @returns any
   */
  private isWechat: any = false;

  /**
   * 判断是否为微信小程序环境
   * @private
   * @returns any
   */
  private isWeatchMini: any = false;

  /**
   * 订单编号
   * @private
   * @returns string
   */
  private orderInfo: any = {
    createdTime: "",
    id: null,
    needPayedAmount: 0,
    orderKind: null,
    orderKindStr: "",
    orderNo: "",
    payDeadlineTime: "",
    productType: null,
    productTypeStr: "",
    status: 1,
    statusStr: "",
    targetId: null,
    targetMainPictUrl: "",
    targetName: "",
    targetPrice: 0,
    targetQty: 1,
  };

  // 产品类别映射
  private productCategoryMap: any = {
    greenway: 4, // 绿通
  };

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 订单编号
   * @private
   * @returns string
   */
  private get orderNo(): string {
    return (this.$route.query && (this.$route.query.orderNo as string)) || "";
  }

  /**
   * 是否匿名
   * @private
   * @returns string
   */
  private get isAnonymous(): boolean {
    return this.$route.query && this.$route.query.anonymous == "1" ? true : false;
  }

  /**
   * 获取数据
   * @private
   * @returns void
   */
  private get appid(): string {
    return localStorage.getItem("appid") || "";
  }

  /**
   * 组件创建钩子
   * @private
   * @returns string
   */
  private async created() {
    this.isWechat = await tools.isWechat();
    this.isWeatchMini = await tools.isWeatchMini();
    this.getPayPreByNo(this.orderNo);
  }

  /**
   * 获取支付信息
   */
  private async getPayPreByNo(No): Promise<void> {
    try {
      if (this.isAnonymous) {
        let { content: result } = await OrderService.instance.getPayPreByNoAnonymous(No);

        if (result.data) {
          this.orderInfo = result.data;
        } else {
          this.$router.go(-1);
        }
      } else {
        let { content: result } = await OrderService.instance.getPayPreByNo(No);

        if (result.data) {
          this.orderInfo = result.data;
        } else {
          this.$router.go(-1);
        }
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 支付失败跳转
   */
  private payFail(): void {
    if (this.orderInfo.orderKind == 4) {
      // 实物订单
      this.$router.push({ name: "goods-order-detail", query: { id: this.orderInfo.id } });
    } else if (this.orderInfo.orderKind == 6) {
      // 绿通
      if (this.orderInfo.productCategory == this.productCategoryMap.greenway) {
        this.$router.push({ name: "greenway-detail", query: { id: this.orderInfo.id } });
      } else {
        // 预约服务
        this.$router.push({ name: "medical-order-detail", query: { id: this.orderInfo.id } });
      }
    } else if (this.orderInfo.orderKind == 2) {
      if (this.isAnonymous) {
        // 体检订单
        this.$router.push({ name: "orderPay", query: { orderNo: this.orderNo } });
      } else {
        // 体检订单
        this.$router.push({ name: "orderDetail", params: { id: this.orderInfo.id } });
      }
    }
  }

  /**
   * 获取支付信息
   */
  private async toPay(): Promise<void> {
    let quitUrl = ""; // 取消
    let returnUrl = ""; // 成功

    let type: Payment_Type = Payment_Type.WechatJSAPI; // 实际支付方式
    if (this.payType == "1") {
      if (this.isWeatchMini) {
        type = Payment_Type.WechatMiniProgram;
      }
      // 微信支付
      else if (!this.isWechat) {
        type = Payment_Type.WechatMWEB;
      }
    } else {
      // 支付宝支付
      type = Payment_Type.AliPayWap;

      if (this.orderInfo.orderKind == 4) {
        // 实物订单
        returnUrl = `${location.origin}/${this.appid}/goods/order/sucess?id=${this.orderInfo.id}`;
        quitUrl = `${location.origin}/${this.appid}/goods/order/detail?id=${this.orderInfo.id}`;
      } else if (this.orderInfo.orderKind == 6) {
        // 绿通
        if (this.orderInfo.productCategory == this.productCategoryMap.greenway) {
          returnUrl = `${location.origin}/${this.appid}/medical/order/sucess?id=${this.orderInfo.id}&productCategory=${this.orderInfo.productCategory}`;
          quitUrl = `${location.origin}/${this.appid}/medical/greenway/detail?id=${this.orderInfo.id}`;
        } else {
          // 预约服务
          returnUrl = `${location.origin}/${this.appid}/medical/order/sucess?id=${this.orderInfo.id}`;
          quitUrl = `${location.origin}/${this.appid}/medical/order/detail?id=${this.orderInfo.id}`;
        }
      } else if (this.orderInfo.orderKind == 2) {
        // 体检订单
        returnUrl = `${location.origin}/${this.appid}/order/middle?id=${this.orderInfo.id}&hasHealthProduct=${this.orderInfo.hasHealthProduct}`;
        quitUrl = `${location.origin}/${this.appid}/order/detail/${this.orderInfo.id}`;
      }
    }

    try {
      let origin = location.origin;
      if (this.employerConfig && this.employerConfig.wptSetReferer) {
        // 应用于vivo套壳
        if (wpt) {
          wpt.setReferer(origin);
        }
      }
      if (this.isAnonymous) {
        let { content: result } = await OrderService.instance.toPayAnonymous(
          {
            orderNo: this.orderNo,
            quitUrl: quitUrl,
            returnUrl: returnUrl,
          },
          type
        );

        if (result.data) {
          this.onBridgeReady(result.data);
        }
      } else {
        let { content: result } = await OrderService.instance.toPay(
          {
            orderNo: this.orderInfo.orderNo,
            quitUrl: quitUrl,
            returnUrl: returnUrl,
          },
          type
        );

        if (result.data) {
          this.onBridgeReady(result.data);
        }
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 支付
   * @static
   * @param  {any} model
   * @returns void
   */
  public onBridgeReady(model: any): void {
    let self = this;
    switch (model.paymentType as Payment_Type) {
      case Payment_Type.WechatJSAPI: // 微信公众号
        {
          let data = model.payRes && JSON.parse(model.payRes);

          WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
              appId: data.appId, // 公众号名称，由商户传入
              timeStamp: data.timeStamp, // 时间戳，自1970年以来的秒数
              nonceStr: data.nonceStr, // 随机串
              package: data.package, //
              signType: data.signType, // 微信签名方式:
              paySign: data.paySign, // 微信签名
            },
            function (res) {
              if (res.err_msg === "get_brand_wcpay_request:ok") {
                if (self.orderInfo.orderKind == 4) {
                  // 实物订单
                  self.$router.push({ name: "goods-order-sucess", query: { id: self.orderInfo.id } });
                } else if (self.orderInfo.orderKind == 6) {
                  // 预约服务
                  self.$router.push({
                    name: "medical-order-sucess",
                    query: { id: self.orderInfo.id, productCategory: self.orderInfo.productCategory },
                  });
                } else if (self.orderInfo.orderKind == 2) {
                  if (self.isAnonymous) {
                    // 体检订单
                    self.$router.push({ name: "orderPay", query: { orderNo: self.orderNo } });
                  } else {
                    // 体检订单
                    self.$router.push({
                      name: "orderMiddle",
                      query: {
                        id: self.orderInfo.id,
                        hasHealthProduct: self.orderInfo.hasHealthProduct + "",
                        orderStatus: model.status + "",
                      },
                    });
                  }
                }
              } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
                // 取消支付
                self.payFail();
              } else {
                // 取消支付
                self.payFail();
              }
            }
          );
        }
        break;
      case Payment_Type.WechatMWEB: // 微信H5
        {
          setTimeout(() => {
            localStorage.setItem("h5Pay", JSON.stringify({ orderId: model.orderId, orderNo: model.orderNo }));
            let href = "";
            // let href = window.location.href;
            // if (href.indexOf("?") > -1)
            // {
            //     href +="&goback=true"
            // }
            // else
            // {
            //     href +="?goback=true"
            // }

            if (self.orderInfo.orderKind == 4) {
              // 实物订单
              href = `${location.origin}/${self.appid}/goods/order/detail?id=${self.orderInfo.id}`;
            } else if (self.orderInfo.orderKind == 6) {
              // 预约服务
              href = `${location.origin}/${self.appid}/medical/order/detail?id=${self.orderInfo.id}`;
            } else if (self.orderInfo.orderKind == 2) {
              let u = navigator.userAgent;
              let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
              // 体检订单
              if (isiOS) {
                if (this.employerConfig.iosRedirectUrl) {
                  href = `${location.origin}/${self.appid}/order/pay/redirectUrl?id=${self.orderInfo.id}`;
                } else {
                  href = `${location.origin}/${self.appid}/order/detail/${self.orderInfo.id}`;
                }
              } else {
                href = `${location.origin}/${self.appid}/order/detail/${self.orderInfo.id}`;
              }
            }

            let url = `${model.payRes}&redirect_url=${encodeURIComponent(href)}`;
            window.location.assign(url);
          }, 1);
        }
        break;
      case Payment_Type.WechatNATIVE: // 微信:扫码NATIVE
      case Payment_Type.WechatAPP: // 微信:APP支付
      case Payment_Type.AliPayWap: {
        // 支付宝:WEB支付
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端

        let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

        if (isiOS) {
          const div = document.createElement("divform");
          div.innerHTML = model.payRes; // data就是接口返回的form 表单字符串

          document.body.appendChild(div);
          document.forms[0].submit();
        } else {
          document.write(model.payRes);
        }

        break;
      }
      case Payment_Type.WechatMiniProgram: {
        let data = model.payRes && JSON.parse(model.payRes);
        let self = this;
        try {
          (wx as any).requestPayment({
            timeStamp: data.timeStamp,
            nonceStr: data.nonceStr,
            package: data.package,
            signType: data.signType,
            paySign: data.paySign,
            success(res) {
              Toast("支付成功");
            },
            fail(res) {
              Toast("支付失败");
            },
            complete() {
              self.payFail();
            },
          });
        } catch (err) {
          console.log(err);
        }
      }
      default:
        Toast("暂不支持该支付方式！");
        break;
    }
  }
}
